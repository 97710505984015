import React from "react";

import css from "./style.module.scss";

import { connect } from "react-redux";

import { Container, CaretDownFilled } from "../../../../";

import { Dropdown, Menu } from "antd";

export const sorterRaw = (props) => {
  return (
    <Container className={css.sorterMainContainer}>
      <Dropdown
        overlay={
          <Menu selectedKeys={[props.sort.by]} style={{ textAlign: "center" }}>
            <Menu.Item
              key="Premium"
              onClick={() => {
                props.onSort({
                  by: "Premium",
                  order: props.sort.order,
                });
              }}
            >
              Premium
            </Menu.Item>
            <Menu.Item
              key="Desductible"
              onClick={() => {
                props.onSort({
                  by: "Deductible",
                  order: props.sort.order,
                });
              }}
            >
              Deductible
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Container className={css.sorterContainer}>
          <Container className={css.sorterTitle}>
            <span>Sort By</span>
            <CaretDownFilled className={css.caretDown} />
          </Container>

          <Container className={css.sorterSelection}>{props.sort.by == "Deductible" ? "Deductible" : "Premium"}</Container>
        </Container>
      </Dropdown>
      <Dropdown
        overlay={
          <Menu selectedKeys={[props.sort.order]}>
            <Menu.Item
              key="asc"
              onClick={() => {
                props.onSort({
                  by: props.sort.by,
                  order: "asc",
                });
              }}
            >
              Lowest
            </Menu.Item>
            <Menu.Item
              key="desc"
              onClick={() => {
                props.onSort({
                  by: props.sort.by,
                  order: "desc",
                });
              }}
            >
              Highest
            </Menu.Item>
          </Menu>
        }
        trigger={[ "click"]}
      >
        <Container className={css.sorterContainer}>
          <Container className={css.sorterTitle}>
            <span>From</span>
            <CaretDownFilled className={css.caretDown} />
          </Container>
          <Container className={css.sorterSelection}>
            {props.sort.order == "asc" ? "Lowest" : "Highest"}
          </Container>
        </Container>
      </Dropdown>
    </Container>
  );
};

const mapStateToProps = ({ sort }) => {
  return {
    sort,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSort: (payload) => {
      return dispatch({
        type: "SORT",
        payload,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(sorterRaw);
