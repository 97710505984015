import React from "react";

import { Button, Container, Icon } from "../../.."

import css from "./style.module.scss"

import MiniSection from "../mini-section";
import CartMiniCard from "../cart-mini-card";

export default ({ cart, onEnroll, onRemove}) => {

    if(!cart) return null;
    
    return <MiniSection title={`Cart (${cart.length})`}>
    <Container className={css.panelContainer}>

        <Container className={css.cartPanelContent}>
            {cart.length == 0 ?  
            <Container className={ css.emptyCart}>
                <Icon icon="empty-cart" fill="#000"/>
                <Container>
                    Your cart is Empty
                </Container>
            </Container>
            
            :<> {cart.map((plan) => {
                return <CartMiniCard onRemove={onRemove} plan={ plan} />
            })
        }
                        <Container onPress={() => { onEnroll && onEnroll()}} className={css.enrollButton}><Button name="quotes-panel-btn-enroll" type="primary">Enroll</Button></Container>
        </>
            }

        </Container>
    </Container>
</MiniSection> }