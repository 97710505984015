import React, { useState } from "react";
import {
  Accordeon,
  FilePdfOutlined,
  Container,
  Icon,
  DownOutlined,
  UpOutlined,
  Popup,
  FilePdfFilled
} from "../../../../..";

import { Table } from "antd";

import { Button } from "antd";

import css from "../style.module.scss";

const { Column, ColumnGroup } = Table;
FilePdfFilled
const renderNetworkText = (text, record) => {
  if (text == "Not Covered") {
    return <Container style={{ fontSize: "12px" }}>- -</Container>;
  } else {
    const words = text.split(" ");
    return <Container className={`${css.bvalues}`}>{text}</Container>;
  }
};

export default (props) => {
  const [benefitsVisible, setBenefitsVisible] = useState(false);
  const [brochureVisible, setBrochureVisible] = useState(false);

  return (
    <Container>
      {benefitsVisible ? (
        <Button
          className={css.bnButton}
          type="primary"
          onClick={() => {
            setBenefitsVisible(!benefitsVisible);
          }}
        >
          {" "}
          Show Less{" "}
        </Button>
      ) : (
        <Button
          className={css.bnButton}
          type="primary"
          onClick={() => {
            setBenefitsVisible(!benefitsVisible);
          }}
        >
          {" "}
          <Icon
            style={{ width: "18px", height: "18px" }}
            icon="corn-check"
            fill="#fff"
          />{" "}
          More Details{" "}
        </Button>
      )}

      {benefitsVisible && (
        <>
        <Table
          onHeaderRow={() => {
            return {
              className: css.header,
            };
          }}
          onRow={(record, index) => {
            return {
              id: record.name,
            };
          }}
          rowClassName={css.benefitsRow}
          dataSource={props.benefits.map((b) => ({ ...b, key: b.name }))}
          pagination={false}
          expandIconColumnIndex={4}
          expandRowByClick={true}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined
                className={css.chevronUp}
                onClick={(e) => {
                  onExpand(record, null);
                }}
              />
            ) : (
              <DownOutlined
                className={css.chevronDown}
                onClick={(e) => {
                  onExpand(record, null);
                }}
              />
            )
          }
          expandable={
            false && {
              expandedRowRender: (record) => (
                <Container className={css.expandedBenefit}>
                  <Container className={css.expandedBenefitBackground} />
                  <Container className={css.expandedBenefitRow}>
                    <Container className={css.expandedBenefitLabel}>
                      In Network:
                    </Container>
                    <Container className={css.expandedBenefitDescription}>
                      {record.in_network}
                    </Container>
                  </Container>
                  <Container className={css.expandedBenefitRow}>
                    <Container className={css.expandedBenefitLabel}>
                      Out of Network:
                    </Container>
                    <Container className={css.expandedBenefitDescription}>
                      {record.out_of_network}
                    </Container>
                  </Container>
                </Container>
              ),
            }
          }
        >
          <Column
            width="55%"
            className={css.planNameColumn}
            dataIndex="name"
            key="name"
            title={() => (
              <Container className={css.benefitsHeader}>Benefits:</Container>
            )}
            render={(text, record) => (
              <>
                <Container className={css.planNameContainer}>
                  {!props.pdf && (
                    <Container className={css.planNameIcon}>
                      <svg className={css.cornCheck}>
                        <use xlinkHref="/sprite.svg#corn-check-icon" />
                      </svg>
                    </Container>
                  )}
                  <Container className={css.planNameText}>{text}</Container>
                </Container>
              </>
            )}
          />
          <ColumnGroup title="Network">
            <Column
              onHeaderCell={() => {
                return { className: css.inNetworkHeader };
              }}
              className={css.planInNetworkColumn}
              title={() => (
                <Container className={css.inNetworkHeader}>In</Container>
              )}
              dataIndex="in_network"
              key="in_network"
              render={renderNetworkText}
            />
            <Column
              className={css.planOutOfNetworkColumn}
              title={() => (
                <Container className={css.outNetworkHeader}>Out</Container>
              )}
              dataIndex="out_of_network"
              render={renderNetworkText}
              key="out_of_network"
            />
          </ColumnGroup>
        </Table>
          {props.plan.brochure_link?.value && (
            <Container className={css.planBrochure}>
              <Button
                onClick={() => {
                  setBrochureVisible(true);
                }}
                danger
              >
                <FilePdfFilled />
                Plan's Brochure
              </Button>
            </Container>
          )}
        </>
      )}

      {props.plan.brochure_link && (
        <Popup
          title={"Plan Brochure"}
          className={css.popupContainer}
          visible={brochureVisible}
          onClose={() => {
            setBrochureVisible(false);
          }}
          openOutside={props.plan.brochure_link.value.replace("http:","https:")}
        >
          <iframe
            style={{ width: "100%" }}
            src={props.plan.brochure_link.value.replace("http:","https:")}
          />
        </Popup>
      )}
    </Container>
  );
};
