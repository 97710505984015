import React from "react"

import css from "./style.module.scss"
import { Button, Container, Icon } from "../../.."

import { formatCurrency, getIconPropsForPlan } from "../../../../utils";

import { connect } from "react-redux"

const CompareMiniCardRaw = ({ plan, specialRates, onRemove }) => {


    const metalLevelColors = {
        Bronze: "#6A3805",
        Catastrophic: "#575988",
        Gold: "#AF9500",
        Platinum: "#3B3B41",
        Silver: "#B4B4B4",
    };

    const iconProps = getIconPropsForPlan(plan);

    return (
        <Container className={css.cardContainer}>
            <Container className={css.header}>
                <Container className={css.name}>{plan.plan_name.value}</Container>
                <Container className={css.carrier}>{plan.carrier.value}</Container>
            </Container>

            <Container className={css.type}>
                <Container className={css.label}>
                </Container>
                <Container style={{display: "flex"}}>
                    <Container className={css.icon}><Icon {...iconProps} style={{ width: "18px", height: "18px" }} /></Container>

                    <Container
                        className={css.planCategory}
                        style={{ color: iconProps.fill }}
                    >
                        {plan.info?.value}
                    </Container>

                </Container>
            </Container>

            <Container className={css.content}>

                <Container className={css.values}>

                    <Container className={`${css.labelValue} ${plan.deductible && plan.deductible.value ? "" : css.singleLabelValue}`}>
                        <Container className={`${css.label} ${plan.carrier.value == "Universal" ? css.smallerLabel : ""}`}>
                
                        </Container>
                        <Container className={`${css.value} ${plan.carrier.value == "Universal" ? css.smallerValue : ""}`} >
                            {(plan.carrier.value !== "National General" || !specialRates) && formatCurrency(plan.premium.value)}
                            {
                                specialRates && plan.carrier.value == "National General" && formatCurrency(specialRates.priorCoverage && !specialRates.prefferedRate ? plan.premium_options.options[1].premium : specialRates.priorCoverage && specialRates.prefferedRate ? plan.premium_options.options[2].premium : plan.premium_options.options[0].premium)
                            }  / Month
                        </Container>
                    </Container>

                </Container>
            </Container>
            {onRemove && <Container onPress={() => { onRemove({ id: plan.id, checked: false}) }} className={css.removeButton}>
                <Icon
                    style={{ marginLeft: "6px", width: "24px", height: "24px" }}
                    fill={"#06315E"}
                    icon="remove-from-cart"
                />
                Remove
            </Container>}
        </Container>
    );
}



const mapStateToProps = ({ specialRates }) => {

    return {
        specialRates
    }
}

export default connect(mapStateToProps)(CompareMiniCardRaw)