import React from "react";
import { Container, Icon } from "..";
import { getIconPropsForPlan } from "../../utils";

import css from "./style.module.scss";

export default (props) => {
  return (
    <Container className={css.planData}>
      <Container className={css.icon}>
        <Icon {...getIconPropsForPlan(props.plan)} />
      </Container>
      <Container className={css.planDataInfo}>
        <Container className={css.carrierName}>
          {props.plan.carrier.value}
        </Container>
        <Container className={css.planName}>
          {props.plan.plan_name.value}
        </Container>
      </Container>
      <Container className={css.premium}>
        $ {props.plan.premium.value?.toFixed(2)}
      </Container>
    </Container>
  );
};
