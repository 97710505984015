import React, { useState } from "react";
import {
  Accordeon,
  FilePdfOutlined,
  Container,
  Icon,
  DownOutlined,
  UpOutlined,
} from "../../../../";
import css from "./style.module.scss";
import { Table } from "antd";

import { Button } from "antd";
import InOut from "./in_out";

import Details from "./details";

import Dental from "./dental";

import Universal from "./universal";


const { Column, ColumnGroup } = Table;

export default (props) => {

  if (
    props.plan.carrier.value == "National General" || props.plan.carrier.value == "Universal" ||
    (props.benefits && props.benefits.length > 0)
  ) {
    const [benefitsVisible, setBenefitsVisible] = useState(props.pdf);

    let Legend = () => null;
    let Content = () => null;

    if (props.benefits[0]?.type == "BENEFIT_IN_OUT") {
      return (
        <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
          <InOut plan={props.plan} benefits={props.benefits} />
        </div>
      );
    } else if (props.plan.carrier.value == "National General") {
      return (
        <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
          <Details plan={props.plan} benefits={props.benefits} />
        </div>
      );
    } else if (props.plan.carrier.value == "Aspire Dental") {
      return <Dental />;
    } else if (props.plan.carrier.value == "Universal") {
      return <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
          <Universal plan={props.plan} benefits={props.benefits} />
        </div>
    } else {
      Legend = () => (
        null
      );
    }

    return (
      <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
        <Legend />
        <Content />
      </div>
    );
  } else return null;
};
